import React from 'react';
import UserStore from './../../../common/stores/ListenerUser';
import Settings from './../../../common/stores/Settings';
import COLORS from './../../../common/lib/colors';

import LoginForm from './form';
import Col from "react-bootstrap/Col";

const orange = COLORS.stats[3];

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.onLogin = this.onLogin.bind(this)
  }

  onLogin (token, callback) {
      UserStore.loginUser(token, callback);
  }

  render() {
      const props = this.props;

      const config = Settings.getConfig();

    return (
      <div className="loginContainer container bg-transparent border-none">
        <div className="page-header mb-4">
          <h1 className="login-logo text-center">
            {!config.site_logo ? null :
              <img src={config.site_logo} title={config.site_name} className="img-responsive"/>
            }
            <span>{config.site_name}</span>
          </h1>
        </div>

        <h3 className="text-center mb-4 text-weight-300" style={{ color: orange }}>Web Listener Login</h3>

        <Col sm={{span: 6, offset: 3}}>
          <LoginForm onLogin={this.onLogin} {...props} />
        </Col>

        <div className="page-footer">
          <div>
            &copy; {new Date().getFullYear()} All Rights Reserved
          </div>
          <div className="footer-link">
            <a href="https://www.sptk.com/" target="_blank" rel="noopener" style={{ color: orange }}>
              www.sptk.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}
